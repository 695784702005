import styled from 'styled-components';
import { ReactNode } from 'react';
import Header from '@/components/layout/header/Header';

export default function MainLayout({ children }: { children: ReactNode }) {
  return (
    <>
      <PageWrapper>
        <Header />
        <main>{children}</main>
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;

  > main {
    flex-grow: 1;
    width: 100%;
  }
`;
