import axios from 'axios';
import { ACCESS_TOKEN_KEY, USER_ID_KEY } from '@/src/constants/localStorageKeys';
const { VITE_MAIN_HOST } = import.meta.env;

export const mainHostWithAuthToken = axios.create({
  baseURL: VITE_MAIN_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
});

mainHostWithAuthToken.interceptors.request.use(
  async (config) => {
    const storageUserId = localStorage.getItem(USER_ID_KEY);
    const storageToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (storageUserId) {
      config.headers['User-Id'] = storageUserId;
    }
    if (storageToken) {
      config.headers['Auth-Token'] = storageToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getRenewToken = async () =>
  await mainHostWithAuthToken.get('/projects/token').then((res) => res.data);

export default {
  getRenewToken,
};
