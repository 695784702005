import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { ACCESS_TOKEN_KEY } from '@/src/constants/localStorageKeys';
import authApi from '@/src/lib/authApi';
import { useSearchParams } from 'react-router-dom';
import { MessageContextData } from '@/contexts/message';

type Props = {
  showMessage: MessageContextData['showMessage'];
  isAuthed: boolean;
  setIsAuthed: Dispatch<SetStateAction<boolean>>;
};

export const useTokenRenewal = ({ showMessage, isAuthed, setIsAuthed }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isRenewingToken, setIsRenewingToken] = useState(false);

  useEffect(() => {
    async function refreshToken() {
      const refreshToken = searchParams.get('refreshToken');
      if (!refreshToken || isRenewingToken || !isAuthed) {
        return;
      }

      setIsRenewingToken(true);
      showMessage({ content: 'Renewing token', type: 'loading', key: 'renewToken', duration: 0 });

      try {
        const res = await authApi.getRenewToken();
        if (res?.access_token) {
          localStorage.setItem(ACCESS_TOKEN_KEY, res.access_token);
          showMessage({
            content: 'Token renewed',
            type: 'success',
            key: 'renewToken',
            duration: 3,
          });
          searchParams.delete('refreshToken');
          setSearchParams({ ...searchParams }, { replace: true });
        } else {
          handleTokenRenewalFailure();
        }
      } catch (e) {
        console.error(e);
        handleTokenRenewalFailure();
      } finally {
        setIsRenewingToken(false);
      }
    }

    function handleTokenRenewalFailure() {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      showMessage({
        content: 'Token renewal failed',
        type: 'error',
        key: 'renewToken',
        duration: 5,
      });
      setIsAuthed(false);
    }

    refreshToken();
  }, [
    searchParams,
    isRenewingToken,
    showMessage,
    setIsRenewingToken,
    setSearchParams,
    setIsAuthed,
    isAuthed,
  ]);

  return { isAuthed, isLoading: isRenewingToken };
};
