import { createContext, ReactNode, useContext, useMemo } from 'react';
import { message } from 'antd';
import { ArgsProps, MessageInstance } from 'antd/es/message/interface';
import { ReactComponent as InfoIcon } from '@/assets/icons/info-circle.svg';
import { ReactComponent as WarningIcon } from '@/assets/icons/danger-circle.svg';
// T-611 TODO replace real icons
import { ReactComponent as ErrorIcon } from '@/assets/icons/danger-triangle.svg';
import { ReactComponent as SuccessIcon } from '@/assets/icons/checkmark.svg';
import styled from 'styled-components';
import Loading from '@/components/loading/Loading';

export interface MessageContextData {
  messageApi: MessageInstance;
  showMessage: (args: ArgsProps) => void;
}

const MessageContext = createContext<MessageContextData>({} as MessageContextData);

const getIconByType = (type: ArgsProps['type']) => {
  switch (type) {
    case 'loading':
      return <Loading />;
    case 'success':
      return <SuccessIcon />;
    case 'error':
      return <ErrorIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'info':
    default:
      return <InfoIcon />;
  }
};

export const MessageProvider = ({ children }: { children: ReactNode }) => {
  const [messageApi, contextHolder] = message.useMessage();

  function showMessage(args: ArgsProps): void {
    const { type = 'info' } = args;
    messageApi
      .open({
        ...args,
        duration: args.duration ? args.duration : 5,
        icon: (
          <StyledIcon className={`global-message-icon-${type}`}>{getIconByType(type)}</StyledIcon>
        ),
        className: `global-message global-message-${type}`,
      })
      .then();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => ({ messageApi, showMessage }), [messageApi]);

  return (
    <MessageContext.Provider value={value}>
      <>
        {contextHolder}
        {children}
      </>
    </MessageContext.Provider>
  );
};

MessageContext.displayName = 'MessageContext';

export const useMessageContext = () => {
  return useContext(MessageContext);
};

const StyledIcon = styled.div`
  display: inline-flex;
  height: 1rem;
  width: 1rem;
  flex: 0 0 1rem;
  justify-content: center;
  align-items: center;
`;
