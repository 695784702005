import z from 'zod';

export const StaticActivitySchema = z.object({
  id: z.number(),
  name: z.string(),
  mgoColor: z.string().optional().nullable(),
  lngColor: z.string().optional().nullable(),
  projectColor: z.string().optional().nullable(),
  mainFuelUnit: z.string(),
});

export type StaticActivitySchemaType = z.infer<typeof StaticActivitySchema>;

export const StaticActivitiesSchema = z.array(StaticActivitySchema);

export type StaticActivitiesSchemaType = z.infer<typeof StaticActivitiesSchema>;
