import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logoUrl from '../../../assets/logo/maress.png';

// T-155 TODO: Next Link is updated, it doesn't take a inside anymore. It gets an a tag on the site, but not in the Storybook DOM
const Header = () => (
  <Wrapper>
    <div className="maress-logo-box">
      <Link to="/" title="Homepage">
        <img alt="MarESS Logo" src={logoUrl} className="maress-logo" />
      </Link>
    </div>
  </Wrapper>
);

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space.md};
  background-color: ${({ theme }) => theme.colors.black500};

  & .maress-logo-box {
    width: 10rem;
  }

  & .maress-logo {
    margin-bottom: 0;
    width: 100%;
  }
`;

export default Header;
