import { JSXElementConstructor, ReactNode } from 'react';
import {
  AttributionControlProps,
  CircleMarkerProps,
  CircleProps,
  FeatureGroupProps,
  GeoJSONProps,
  ImageOverlayProps,
  LayerGroupProps,
  LayersControlProps,
  MapContainerProps,
  MarkerProps,
  PaneProps,
  PolygonProps,
  PolylineProps,
  PopupProps,
  RectangleProps,
  ScaleControlProps,
  SVGOverlayProps,
  TileLayerProps,
  TooltipProps,
  VideoOverlayProps,
  WMSTileLayerProps,
  ZoomControlProps,
} from 'react-leaflet';
import Leaflet from 'leaflet';
import { Dayjs } from 'dayjs';
import { PeriodSchemaType } from '@/src/@types/schemas/projects/vesselPeriod';

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TEXT = 'text',
  WARNING = 'warning',
  PLAIN = 'plain',
  DARK = 'dark',
  UNSTYLED = 'unstyled',
}

export enum GenericType {
  BASELINE = 'Baseline',
  GOAL = 'Goal',
}
export enum WeatherType {
  WIND = 'Wind',
  WAVES = 'Waves',
}

export enum WeatherIcon {
  WIND = 'wind',
  WAVES = 'waves',
}

export enum WeatherStatus {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum GraphUnit {
  DOLLAR = 'dollar',
  MILLIONDOLLAR = 'milliondollar',
  METRICTONNES = 'metric-tonnes',
  MILLION = 'million',
  KILO = 'kilo',
  DAYS = 'days',
  DEFAULT = 'default',
}

export enum GraphUnitPrefix {
  KILO = 'kg',
  METRICTONNES = 'mt',
}

export enum ButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
}

export enum StatusColor {
  GREEN = 'green',
  RED = 'red',
}

export enum SkeletonLoaderVariant {
  HORIZONTALGRAPH = 'horizontal-graph',
  STARS = 'stars',
  PIE = 'pie',
  CIRCLE = 'circle',
  BAR = 'bar',
  TABLE = 'table',
}

export type RadioButtonType = {
  // id: string;
  value: string | number;
  label: string;
  disabled?: boolean;
};

export type WeatherIconType = {
  status: WeatherStatus;
  iconType: WeatherIcon;
  value?: number | string;
  rounded?: boolean;
};

export type UserType = {
  firstName: string;
  lastName: string;
  title?: string;
  email?: string;
};

export type HorizontalBarType = {
  total?: number; // Does normally expect to get total and value
  value?: number; // Does normally expect to get total and value
  name?: string;
  unit?: GraphUnit;
  small?: boolean;
  withPoles?: boolean;
  milestone?: number;
  milestoneTitle?: string;
  milestoneColor?: string;
  numberColor?: string;
  barColor?: string;
  barHeightRem?: number;
};

export type DurationGraphType = {
  startDate?: string; // Should always be provided, but it doesnt crashes without it
  endDate?: string; // Should always be provided, but it doesnt crashes without it
  currentDate?: string;
  barColor?: string;
  showDates?: boolean;
  name?: string;
  small?: boolean;
};

export type DurationPeriodsGraphType = Omit<DurationGraphType, 'startDate' | 'endDate'> & {
  small?: boolean;
  periods: PeriodSchemaType[];
};

export type BulletGraphType = HorizontalBarType & {
  legend: string;
  baseLine?: number;
  target?: number;
  legendWidth?: number;
  unitPrefix?: GraphUnitPrefix;
  showNumbers?: boolean;
  hasBorder?: boolean;
};

export type LegendItemType = {
  id: string;
  name: string;
  color?: string;
};

export type StakeholderCardType = {
  name: string;
  ownershipPercent: number;
  totalEmissions: number;
  bulletGraphs?: BulletGraphType[];
  circleSize?: number;
  circleColor?: string;
};

export type BaselineChallenges = {
  name: string;
  currentNumber: number;
  previousNumber: number;
  accumulatedText: string;
  stars: number;
};

export type BaseChartData = {
  name: string;
  value: number;
  activityId?: number;
  color?: string;
};

export type SeriesChartData = BaseChartData & {
  series: string;
};

export type TimeChartData = BaseChartData & {
  time: string;
};

export type TimeDualChartData = Omit<TimeChartData, 'value'> & {
  valueDual: number;
};

export type DualAxesChartData = {
  data: TimeChartData[];
  dataDual: TimeDualChartData[];
};

export type BarChartData = {
  data: TimeChartData[];
};

// Leaflet Map
export type MapChildComponentConstructors = {
  AttributionControl: JSXElementConstructor<AttributionControlProps>;
  Circle: JSXElementConstructor<CircleProps>;
  CircleMarker: JSXElementConstructor<CircleMarkerProps>;
  FeatureGroup: JSXElementConstructor<FeatureGroupProps>;
  GeoJSON: JSXElementConstructor<GeoJSONProps>;
  ImageOverlay: JSXElementConstructor<ImageOverlayProps>;
  LayerGroup: JSXElementConstructor<LayerGroupProps>;
  LayersControl: JSXElementConstructor<LayersControlProps>;
  Marker: JSXElementConstructor<MarkerProps>;
  Pane: JSXElementConstructor<PaneProps>;
  Polygon: JSXElementConstructor<PolygonProps>;
  Polyline: JSXElementConstructor<PolylineProps>;
  Popup: JSXElementConstructor<PopupProps>;
  Rectangle: JSXElementConstructor<RectangleProps>;
  SVGOverlay: JSXElementConstructor<SVGOverlayProps>;
  ScaleControl: JSXElementConstructor<ScaleControlProps>;
  TileLayer: JSXElementConstructor<TileLayerProps>;
  Tooltip: JSXElementConstructor<TooltipProps>;
  VideoOverlay: JSXElementConstructor<VideoOverlayProps>;
  WMSTileLayer: JSXElementConstructor<WMSTileLayerProps>;
  ZoomControl: JSXElementConstructor<ZoomControlProps>;
};

export type MapProps = Omit<MapContainerProps, 'children'> & {
  className?: string;
  width?: number;
  height?: number;
  children: (
    mapChildComponentConstructors: MapChildComponentConstructors, // typeof ReactLeaflet does work, but would be misguiding
    map: typeof Leaflet
  ) => ReactNode;
};

// Timeline
export type TimelineBase = {
  startDate: string | Date | Dayjs;
  endDate: string | Date | Dayjs;
};

export type TimelineAction = {
  name: string;
  handleOnClick: VoidFunction;
  icon: ReactNode;
};

export enum TimelineRowTypes {
  PERIOD = 'period',
  SEASTATE = 'seastate',
  GRAPH = 'graph',
  EMPTY = 'empty',
}

export type TimelineRowEmpty = {
  rowType: TimelineRowTypes.EMPTY;
  id: string;
  rowSpan?: number;
};

export type TimelineRowPeriodBase = {
  rowType: TimelineRowTypes.PERIOD;
  id: string;
  name: string;
  forecastingSeaStates?: SeaState[];
  rowStartDate: string | Date;
  rowEndDate: string | Date;
  periods: PeriodSchemaType[];
  rowSpan?: number;
};

export type SeaState = {
  valueWave: number;
  valueWind: number;
  date: string | Date;
};

export type TimelineRowSeaStateBase = {
  rowType: TimelineRowTypes.SEASTATE;
  id: string;
  name: string;
  rowStartDate: string | Date;
  seaStates: SeaState[];
  rowSpan?: number;
};

export type TimelineRowGraphBase = BarChartData & {
  rowType: TimelineRowTypes.GRAPH;
  id: string;
  name: string;
  rowSpan?: number;
  dataValueField?: string;
  minTime?: string;
  maxTime?: string;
};

export type TimelineRowBaseTypes =
  | TimelineRowEmpty
  | TimelineRowPeriodBase
  | TimelineRowSeaStateBase
  | TimelineRowGraphBase;

export enum TimelineLabelTypes {
  DEFAULT = 'default',
  PERIOD = 'period',
  ACTIONS = 'actions',
}

export type TimelineLabelsDefaultBase = {
  labelType: TimelineLabelTypes.DEFAULT;
  name: ReactNode;
  id: string;
  actions?: TimelineAction[];
  rowSpan?: number;
};

export type TimelineLabelsPeriodBase = {
  labelType: TimelineLabelTypes.PERIOD;
  name: string;
  id: string;
  actions?: TimelineAction[];
  url: string;
  isActive: boolean;
  rowSpan?: number;
};

export type TimelineLabelsActionBase = {
  labelType: TimelineLabelTypes.ACTIONS;
  name: string;
  id: string;
  actions?: (TimelineAction & { isActive: boolean })[];
  rowSpan?: number;
};

export type TimelineLabelsBaseTypes =
  | TimelineLabelsDefaultBase
  | TimelineLabelsPeriodBase
  | TimelineLabelsActionBase;

export type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  onDismiss: () => void;
  onConfirm?: () => void;
  confirmText?: string;
  confirmDisabled?: boolean;
  dismissText?: string;
  dismissDisabled?: boolean;
  backgroundColor?: string;
  hideFooter?: boolean;
  buttonPlacement?: 'left' | 'right' | 'center';
  width?: string;
};

export type ToggleButtonsOption = {
  name: string;
  value: string;
  disabled?: boolean;
};

export type ToggleButtonsProps = {
  options: ToggleButtonsOption[];
  onChange: (value: ToggleButtonsOption['value']) => void;
  currentOption: ToggleButtonsOption['value'];
  disabled?: boolean;
  className?: string;
};

export type ActivePeriod = {
  startDate: string | Date;
  endDate: string | Date;
  id: string;
};

export type MarkerIcon = {
  vesselType?: VesselType;
  color?: string;
  iconWidth?: number;
  iconHeight?: number;
};

export enum VesselType {
  CSV = 'CSV',
  SEMI_SUB = 'Semi-sub',
  HIGH_SPEED_FERRY = 'High Speed Ferry',
  AHTS = 'AHTS',
  HEAVY_LIFT = 'Heavy Lift',
  PSV = 'PSV',
  SHUTTLE_TANKER = 'Shuttle Tanker',
  CREW_TRANSFER = 'Crew Transfer',
}

export type MaressVesselType = {
  type?: string | null;
  color?: string | null;
};
