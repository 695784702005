import styled from 'styled-components';
import { screenSizes } from '@/styles/variables';

export const CenterContent = styled.div`
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.space.md};
  max-width: ${screenSizes.screenWidthMega};
`;

export const DevComponentWrapper = styled.div<{ vw?: number }>`
  min-width: ${(props) => (props.vw ? `${props.vw}vw` : '80vw')};
`;
