import React, { ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components';

interface IErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

type Props = {
  children: ReactNode;
  renderFallback?: (props: ErrorFallbackProps) => ReactNode;
};

type ErrorFallbackProps = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, errorInfo }) => {
  console.error({ error, errorInfo });

  return (
    <StyledFallback>
      <div>
        <h1>Oops! Something went wrong</h1>
        <pre>{error?.toString()}</pre>
        <p>
          Contact the{' '}
          <a
            href="mailto:maress@vpsveritas.com"
            title="Email the VPS developer team"
            aria-label="Email the VPS developer team"
          >
            VPS developer team
          </a>{' '}
          for assistance. Thank you for your patience!
        </p>
      </div>
    </StyledFallback>
  );
};

class ErrorBoundary extends React.Component<Props, IErrorBoundaryState> {
  state: IErrorBoundaryState = { hasError: false, error: null, errorInfo: null };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_: unknown) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
    console.error(error, errorInfo);
  }

  render() {
    const { renderFallback } = this.props;

    if (this.state.hasError) {
      return renderFallback ? (
        renderFallback({ error: this.state.error, errorInfo: this.state.errorInfo })
      ) : (
        <ErrorFallback error={this.state.error} errorInfo={this.state.errorInfo} />
      );
    }

    return this.props.children;
  }
}

const StyledFallback = styled.div<{ fullScreen?: boolean }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.black500};
  margin: 0 auto;
  & > div {
    margin: 0 auto;
    width: 100%;
    max-width: 70rem;
    padding: ${({ theme }) => theme.space.lg};
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-weight: bold;
      font-size: 2rem;
    }
    pre {
      color: ${({ theme }) => theme.colors.warning};
      font-size: clamp(0.6rem, 0.9vw, 1rem);
      white-space: pre-wrap;
      word-break: break-all;
      margin: 0;
      padding: ${({ theme }) => `${theme.space.md} ${theme.space.lg}`};
      background: ${({ theme }) => theme.colors.black600};
      border-radius: 0.5rem;
    }
    p {
      font-size: 1.3rem;
      margin-top: 2rem;
      a {
        font-weight: 500;
        text-decoration: ${({ theme }) => `underline ${theme.colors.primary100}`};
        padding: 5px;
        &:hover:focus {
          outline: ${({ theme }) => `2px solid ${theme.colors.primary100}`};
          text-decoration: none;
        }
      }
    }
  }
`;

export default ErrorBoundary;
