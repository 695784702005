import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProjectOutlet from '@/routes/projects/ProjectOutlet';
import Loading from '@/components/loading/Loading';
import { useAuthContext } from '@/contexts/auth';
import { useStaticsContext } from '@/contexts/statics';
import NotAuthed from '@/routes/misc/NotAuthed';

const ProjectOverview = lazy(() => import('./projects/ProjectsOverview'));
const ProjectSingle = lazy(() => import('./projects/ProjectSingle'));
const ProjectSettings = lazy(() => import('./projects/ProjectSettings'));
const ProjectReport = lazy(() => import('./projects/ProjectReport'));
const ProjectVesselSingle = lazy(() => import('./projects/VesselSingle'));
const ProjectLogsPage = lazy(() => import('./projects/ProjectLogs'));
const InputModule = lazy(() => import('./inputModule'));
const NotFound = lazy(() => import('./misc/NotFound'));

const Router: React.FC = () => {
  const { isLoading, isAuthed } = useAuthContext();
  const { isLoading: isLoadingStatics } = useStaticsContext();
  if (isLoading || isLoadingStatics) return <Loading fullScreen={true} />;
  if (!isAuthed) return <NotAuthed />;
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Loading fullScreen />}>
            <ProjectOverview />
          </Suspense>
        }
      />
      <Route
        path="/input-module"
        element={
          <Suspense fallback={<Loading fullScreen />}>
            <InputModule />
          </Suspense>
        }
      />
      {/* Projects */}
      <Route path="/projects">
        <Route
          path="/projects"
          element={
            <Suspense fallback={<Loading fullScreen />}>
              <ProjectOverview />
            </Suspense>
          }
        />
        <Route
          path="/projects/:projectId"
          element={
            <Suspense fallback={<Loading fullScreen />}>
              <ProjectOutlet />
            </Suspense>
          }
        >
          <Route
            path="/projects/:projectId"
            element={
              <Suspense fallback={<Loading fullScreen />}>
                <ProjectSingle />
              </Suspense>
            }
          />
          <Route
            path="/projects/:projectId/report"
            element={
              <Suspense fallback={<Loading fullScreen />}>
                <ProjectReport />
              </Suspense>
            }
          />
          <Route
            path="/projects/:projectId/settings"
            element={
              <Suspense fallback={<Loading fullScreen />}>
                <ProjectSettings />
              </Suspense>
            }
          />
          <Route
            path="/projects/:projectId/logs"
            element={
              <Suspense fallback={<Loading fullScreen />}>
                <ProjectLogsPage />
              </Suspense>
            }
          />
          <Route
            path="/projects/:projectId/vessels/:vesselId"
            element={
              <Suspense fallback={<Loading fullScreen />}>
                <ProjectVesselSingle />
              </Suspense>
            }
          />
        </Route>
      </Route>
      <Route
        path="*"
        element={
          <Suspense fallback={<Loading fullScreen />}>
            <NotFound />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
