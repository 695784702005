import { css, keyframes } from 'styled-components';

export const screenSizes = {
  screenWidthMini: '500px',
  screenWidthSmall: '768px',
  screenWidthMedium: '1024px',
  screenWidthLarge: '1200px',
  screenWidthXl: '1550px',
  screenWidthMega: '1800px',
  maxWidth: '1300px',
};

export const buttonReset = css`
  padding: 0.5rem;
  border: none;
  font-size: ${({ theme }) => theme.fonts.fontSizeBody};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const bodyFont = css`
  size: ${({ theme }) => theme.fonts.fontSizeBody};
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const focusStyle = css`
  outline: 2px solid ${({ theme }) => theme.colors.primary500};
  outline-offset: 0.125rem;
`;

export const bounceProgressBar = keyframes`
  0%,50%,100% {
    transform: scaleX(1)
  }

  40%,80% {
    transform: scaleX(1.05)
  }
`;

export const boxShadow = css`
  box-shadow: 4px 4px 8px rgba(29, 34, 38, 0.4);
`;

export const svgDropShadow = css`
  filter: drop-shadow(4px 4px 8px rgba(29, 34, 38, 0.4));
`;
