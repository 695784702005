import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyles = createGlobalStyle`
  ${normalize}

  * {
    font-family: ${({ theme }) => theme.fonts.primary};
  }

  *, *:after, *:before {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    background-color: ${({ theme }) => theme.colors.black500};
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme }) => theme.colors.text};
    overscroll-behavior-x: none; // disables next/prev page swipe
  }

  html {
    font-size: ${({ theme }) => theme.fonts.fontSizeHtml};
  }

  body {
    font-size: ${({ theme }) => theme.fonts.fontSizeBody};
  }

  h1, h2, h3, h4, h5, h6, p,
  ul, ol, li, dl, dt, dd,
  blockquote, figure, hr,
  pre, img, details, summary,
  input, textarea, select,
  fieldset, legend {
    margin-top: ${({ theme }) => theme.space.none};
    margin-bottom: ${({ theme }) => theme.space.md};
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: ${({ theme }) => theme.fonts.headingFont};
    font-weight: normal;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  // for printing
  .no-page-break-on-print {
    @media print {
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
  .hide-on-print {
    @media print {
      display: none !important;
    }
  }
  .hide-on-screen {
    @media screen {
      display: none !important;
    }
  }

  // messages
  .global-message {
    .ant-message-notice-content {
      font-size: 1rem;
      border-radius: 1rem;
      padding: ${({ theme }) =>
        `${theme.space.sm} ${theme.space.md} ${theme.space.sm} ${theme.space.sm}`};
      text-align: left;
      max-width: 25rem;
      line-height: 1.25;

      .ant-message-custom-content {
        display: inline-flex;
        align-items: center;
        gap: ${({ theme }) => theme.space.sm};
      }
    }

    &.global-message-info .ant-message-custom-content svg > path {
      stroke: ${({ theme }) => theme.colors.primary500};
    }

    &.global-message-success .ant-message-custom-content svg > path {
      stroke: ${({ theme }) => theme.colors.success};
    }

    &.global-message-warning .ant-message-custom-content svg > path {
      stroke: ${({ theme }) => theme.colors.warning};
    }

    &.global-message-error .ant-message-custom-content svg > path {
      stroke: ${({ theme }) => theme.colors.warning};
    }

    &.global-message-loading .ant-message-notice-content {
      text-align: center;
      padding: ${({ theme }) => `${theme.space.sm} ${theme.space.md}`};

      .ant-message-custom-content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: ${({ theme }) => theme.space.xs};
      }

      .global-message-icon-loading {
        width: auto;
        height: 0.5rem;
          //padding: ${({ theme }) => `${theme.space.sm} ${theme.space.sm}`};
        > div {
          height: 0.5rem;
        }

        svg {
          width: 0.75rem;
          height: 0.75rem;
        }
      }

      background: ${({ theme }) => theme.colors.black600};
      color: ${({ theme }) => theme.colors.text};
    }
  }
`;

export default GlobalStyles;
