import projectsApi from '@/src/lib/projectsApi';
import useSWR from 'swr';
import { errorInterceptor, jwtExpiredMessage } from '@/src/utils/errorHelper';
import { ProjectSchemaType } from '@/src/@types/schemas/projects/project';

type Props = {
  projectId: string | undefined;
};

export default function useProject({ projectId }: Props) {
  const shouldFetch = projectId !== undefined;
  const { data, error, isLoading, mutate } = useSWR(
    shouldFetch ? [`api/projects/${projectId}`, projectId] : null,
    // url is not used, but is needed for the cache key
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([url, projectId]): Promise<ProjectSchemaType> => projectsApi.getProject({ projectId }),
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        // Never retry on 404.
        const err = errorInterceptor(error);
        if (err?.status === 404) return;
        if (err.status === 401 && err.message !== jwtExpiredMessage) {
          // no access to this project, redirect to projects page
          return (window.location.href = '/#/projects');
        }

        // Only retry up to 5 times.
        if (retryCount >= 5) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount }), 5000);
      },
    }
  );

  return {
    isLoading,
    error,
    data,
    mutate,
  };
}
