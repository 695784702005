import { TableEntryType } from '@/src/@types/schemas/inputModule/entry';
import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';

dayjs.extend(minMax);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

type DateType = string | Date | Dayjs;

export const getDurationInUnit = ({
  currentDate,
  startDate,
  endDate,
  unit = 'day',
}: {
  currentDate?: string;
  startDate: string;
  endDate: string;
  unit?: OpUnitType;
}) => {
  const now = currentDate ? getDayjs(currentDate) : getDayjs();
  const end = getDayjs(endDate);
  const start = getDayjs(startDate);
  const isNowBeforeStart = now.isBefore(start);
  const isNowAfterEnd = now.isAfter(end);
  const isNowInPeriod = !isNowBeforeStart && !isNowAfterEnd;
  const totalDuration = end.diff(start, unit) + 1;

  function findDuration() {
    if (isNowInPeriod) {
      return now.diff(start, unit); // In progress
    }
    if (!isNowBeforeStart) {
      return totalDuration; // Finished
    }
    return 0; // Not started yet
  }

  const currentDuration = findDuration();

  return {
    totalDuration,
    currentDuration,
  };
};

export const isDateInPeriod = ({
  date,
  startDate,
  endDate,
}: {
  date?: DateType; // optional because if we want to check if current date is in period, we don't need to provide date
  startDate: DateType;
  endDate: DateType;
}) => {
  const start = getDayjs(startDate);
  const end = getDayjs(endDate);
  return getDayjs(date).isBetween(start, end, null, '[]');
};

export const getDateFormat = (date?: DateType) => {
  if (date === undefined) return ''; // return empty string if no date is provided, because dayjs will return current date, or should we return null?
  return getDayjs(date).format('DD.MM.YYYY');
};

export const getTimeFormat = (date?: DateType) => {
  if (date === undefined) return ''; // return empty string if no date is provided, because dayjs will return current date, or should we return null?
  return getDayjs(date).format('HH:mm');
};

//TODO: Look at this again
export const getDayjsMax = (dates: (DateType | undefined)[]): Dayjs => {
  return dayjs.max(dates.map((date) => getDayjs(date))) ?? dayjs();
};

export const getDayjsMin = (dates: (DateType | undefined)[]): Dayjs => {
  return dayjs.min(dates.map((date) => getDayjs(date))) ?? dayjs();
};

// use this function to get dayjs instance, for easier global configuration
export const getDayjs = (date?: DateType): Dayjs => {
  return dayjs(date);
};

export function getFullDayName(date: string | undefined) {
  if (date === undefined) return '';
  return new Date(date).toLocaleDateString('en-US', { weekday: 'long' });
}

export function getEntryStartTime(entry: TableEntryType | undefined) {
  if (entry === undefined) return '00:00';
  if (entry.endTime === '23:59') return '00:00';
  return entry.endTime;
}

export function getEntryReportDate(entry: TableEntryType | undefined) {
  if (entry === undefined) {
    return dayjs().format('YYYY-MM-DD');
  }
  if (entry.endTime === '23:59') {
    return dayjs(entry?.reportDate).add(1, 'day').format('YYYY-MM-DD');
  }
  return entry.reportDate;
}

//Testing: https://www.calculatorsoup.com/calculators/time/decimal-to-time-calculator.php
export function convertHoursToHHmm(hours: number | undefined) {
  if (hours === undefined) return '00:00';
  if (hours.toString().split('.')[0] === '24' || hours.toString() === '24') return '23:59';

  const hourParts = hours.toString().split('.');
  const hourStr = hourParts[0].length === 1 ? `0${hourParts[0]}` : hourParts[0];
  if (hourParts.length === 1) {
    return `${hourStr}:00`;
  }
  const hourFraction = Number(hourParts[1]) / Math.pow(10, hourParts[1]?.length);
  const hourInMinutes = hourFraction * 60;
  const minuteStr =
    hourInMinutes < 10 ? `0${hourInMinutes.toFixed(0)}` : Math.floor(hourInMinutes).toString();
  return `${hourStr}:${minuteStr}`;
}

// Testing: https://www.inchcalculator.com/time-to-hours-calculator/
export function convertHHmmToHours(timeValue: string) {
  const timeParts = timeValue.split(':');
  const hour = Number(timeParts[0]);
  const minutes = Number(timeParts[1]);
  return hour + minutes / 60;
}

export function getTZOffset(date?: string) {
  const dateInput = date !== undefined && date !== '' ? date : new Date();
  const timezoneOffsetMinutes = new Date(dateInput).getTimezoneOffset();
  const hours = Math.floor(Math.abs(timezoneOffsetMinutes / 60))
    .toString()
    .padStart(2, '0');
  const minutes = (Math.abs(timezoneOffsetMinutes) % 60).toString().padStart(2, '0');
  const sign = timezoneOffsetMinutes > 0 ? '-' : '+';

  return `${sign}${hours}:${minutes}`;
}

export function getEntryTZOffset(entry?: TableEntryType | null) {
  if (entry?.timeOffset === 'Z') {
    return '+00:00';
  }
  return entry?.timeOffset ?? '+00:00';
}

export function getTZOffsetHours() {
  return new Date().getTimezoneOffset() / 60;
}

// https://www.inchcalculator.com/subtract-times-calculator/
export function calculateDurationInHours(startTime: string, endTime: string) {
  let adjustedEndTime = endTime;
  if (endTime === '23:59') {
    adjustedEndTime = '24:00';
  }
  const durationInMinutes = dayjs(adjustedEndTime, 'HH:mm').diff(
    dayjs(startTime, 'HH:mm'),
    'minutes'
  );
  return durationInMinutes / 60;
}

export function timeRefineFunction(str: string) {
  const [hours, minutes] = str.split(':');
  return Number(hours) >= 0 && Number(hours) <= 23 && Number(minutes) >= 0 && Number(minutes) <= 59;
}

export function addHHmmToDurationInHours(timeStr: string | undefined, duration: number) {
  if (timeStr === undefined) return '00:00';
  if (timeStr === '00:00' && duration === 24) return '23:59';
  return dayjs(timeStr, 'HH:mm').add(duration, 'hour').format('HH:mm');
}

export function addHHmmToDurationInMinutes(timeStr: string | undefined, duration: number) {
  if (timeStr === undefined) return '00:00';
  return dayjs(timeStr, 'HH:mm').add(duration, 'minute').format('HH:mm');
}

export function addISODateToDurationInHours(date: string, duration: number) {
  return dayjs.utc(date).add(duration, 'hour').format('YYYY-MM-DDTHH:mm:ss[Z]');
}

export function groupItemsByReportDate<T extends { reportDate: string }>(data: T[]) {
  return data.reduce((acc, curr) => {
    const entries = acc.get(curr.reportDate);
    if (!acc.has(curr.reportDate) || entries === undefined) {
      acc.set(curr.reportDate, [curr]);
      return acc;
    } else {
      entries.push(curr);
      acc.set(curr.reportDate, entries);
      return acc;
    }
  }, new Map<string, T[]>());
}

export function addStartAndEndTimes<
  T extends { reportDate: string; timePeriod: number; reportHours: number }
>(data: T[]) {
  const entriesGroupedByDate = groupItemsByReportDate(data);

  const allEntries = [];
  const groupedEntriesArrays = Array.from(entriesGroupedByDate.values());
  for (const entryArray of groupedEntriesArrays) {
    const sortedEntryArray = [...entryArray].sort((a, b) => (a.timePeriod < b.timePeriod ? -1 : 1));
    const entriesWithTimes = sortedEntryArray.map((entry) => ({
      ...entry,
      startTime: '',
      endTime: '',
    }));
    for (let i = 0; i < entriesWithTimes.length; i++) {
      const entry = entriesWithTimes[i];
      if (i === 0) {
        entry.startTime = '00:00';
        entry.endTime = addHHmmToDurationInHours(entry.startTime, entry.reportHours);
      } else {
        entry.startTime = addHHmmToDurationInHours(
          entriesWithTimes[i - 1].startTime,
          entriesWithTimes[i - 1].reportHours
        );
        entry.endTime = addHHmmToDurationInHours(entry.startTime, entry.reportHours);
      }
    }
    allEntries.push(entriesWithTimes);
  }
  return allEntries.flat();
}

export function createModeDate(dateStr: string, timeStr: string) {
  return dayjs.utc(`${dateStr} ${timeStr}`, 'YYYY-MM-DD HH:mm').format();
}

export function getTimeFromModeDate({
  modeDate,
  isEndDate,
}: {
  modeDate?: string;
  isEndDate?: boolean;
}) {
  if (modeDate === undefined) return '00:00';
  const time = dayjs.utc(modeDate).format('HH:mm');
  if (isEndDate && time === '00:00') return '23:59';
  return dayjs.utc(modeDate).format('HH:mm');
}
