import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as VpsArrow } from '@/assets/icons/orange-arrow.svg';

type Props = {
  fullScreen?: boolean;
  className?: string;
};

const Loading: React.FC<Props> = ({ fullScreen = false, className }: Props) => {
  return (
    <Wrapper className={className} fullScreen={fullScreen}>
      <VpsArrow />
      <VpsArrow />
      <VpsArrow />
      <VpsArrow />
      <VpsArrow />
    </Wrapper>
  );
};
const loadingFade = keyframes`
  0%, 100%  {
    opacity: 1;
  }

  50%{
    opacity: 0;
  }
`;

const Wrapper = styled.div<{ fullScreen?: boolean }>`
  display: flex;
  align-items: center;
  height: 2rem;
  svg {
    width: 1.5rem;
    transform: rotate(180deg);
    animation: ${loadingFade} 1.6s backwards infinite;
    &:nth-of-type(2) {
      animation-delay: 0.3s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.6s;
    }
    &:nth-of-type(4) {
      animation-delay: 0.9s;
    }
    &:nth-of-type(5) {
      animation-delay: 1.2s;
    }
  }
  ${({ fullScreen }) =>
    fullScreen &&
    `
      min-height: calc(100vh - 3rem);
      justify-content: center;
    `}
`;

export default Loading;
