import { Outlet, useOutletContext } from 'react-router-dom';
import useProjectContext, { ProjectContextType } from '@/contexts/projects/useProjectContext';

/*
 *
 * Route outlet for "/projects/:projectId/*"
 * Outlet is a component that will be rendered in the route and will have access to the context
 * Project context will contain the project settings, logic and hold the state of the project
 *
 * */
const ProjectOutlet = () => {
  const context = useProjectContext();
  return <Outlet context={context} />;
};

export function useProjectOutletContext() {
  return useOutletContext<ProjectContextType>();
}

export default ProjectOutlet;
