import { AxiosError, isAxiosError } from 'axios';

interface ErrorResponse {
  status: number;
  message: string;
}

function isJwtExpired(message: string): boolean {
  const jwtExpiredString = 'Jwt expired';
  return message.includes(jwtExpiredString);
}

export const jwtExpiredMessage = 'JWT expired';

const handleAxiosError = (error: AxiosError): ErrorResponse => {
  if (error.response) {
    // check for JWT token is expired error
    const responseData = error.response?.data;
    const responseErrorMessage =
      responseData && typeof responseData === 'object' && 'message' in responseData
        ? `${responseData.message}`
        : undefined;
    if (responseErrorMessage && isJwtExpired(responseErrorMessage)) {
      return {
        status: 401,
        message: jwtExpiredMessage,
      };
    } else if (responseErrorMessage) {
      return {
        status: error.response.status,
        message: responseErrorMessage,
      };
    }
    return {
      status: error.response.status,
      message: error.message,
    };
  } else if (error.request) {
    return {
      status: error?.request?.status ?? 0,
      message: error.request.toString(),
    };
  } else {
    return {
      status: error.status ?? 0,
      message: error.message ?? 'An unexpected error occurred',
    };
  }
};

const handleUnexpectedError = (error: unknown): ErrorResponse => {
  if (typeof error === 'string') {
    return {
      status: 0,
      message: error,
    };
  } else if (error instanceof Error) {
    return {
      status: 0,
      message: error.message ?? error.name ?? 'An unexpected error occurred',
    };
  } else {
    return {
      status: 0,
      message: 'An unexpected error occurred',
    };
  }
};

export const errorInterceptor = (error: unknown): ErrorResponse => {
  let errorResponse: ErrorResponse;
  if (isAxiosError(error)) {
    errorResponse = handleAxiosError(error);
  } else {
    errorResponse = handleUnexpectedError(error);
  }
  console.error(errorResponse);
  return errorResponse;
};
