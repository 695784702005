import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { EntryConfigType } from '@/src/@types/schemas/inputModule/config';

type ConfigContextType = {
  defaultEntriesConfig: EntryConfigType;
  entriesConfig: EntryConfigType;
  setEntriesConfig: Dispatch<SetStateAction<EntryConfigType>>;
};

let defaultEntriesConfig: EntryConfigType = {
  limit: 1000,
  monthsBack: 15,
  editable: true,
};

try {
  const storedBEConfigDefault = localStorage.getItem('config');
  if (storedBEConfigDefault != null) {
    const BEConfigDefault = JSON.parse(storedBEConfigDefault);
    if (BEConfigDefault.entries !== undefined) {
      defaultEntriesConfig = BEConfigDefault.entries;
    }
  }
} catch (e) {
  console.error('Error parsing entries config:', e);
}

// Check for entries config saved by the user. Set the config if available and store the default if null
let entriesConfig = defaultEntriesConfig;
try {
  const storedEntriesConfig = localStorage.getItem('entries_config');
  if (storedEntriesConfig != null) {
    entriesConfig = JSON.parse(storedEntriesConfig);
  } else {
    localStorage.setItem('entries_config', JSON.stringify(defaultEntriesConfig));
  }
} catch (e) {
  console.error('Error parsing entries config:', e);
  localStorage.setItem('entries_config', JSON.stringify(defaultEntriesConfig));
  window.location.reload();
}

const initialState = {
  defaultEntriesConfig,
  entriesConfig: entriesConfig,
  setEntriesConfig: () => {
    console.log('Default entries config currently used');
  },
};

export const ConfigContext = createContext<ConfigContextType>(initialState);

export const AppConfigProvider = ({ children }: { children: ReactNode }) => {
  const [entriesConfig, setEntriesConfig] = useState<EntryConfigType>(initialState.entriesConfig);

  const value = useMemo(
    () => ({
      defaultEntriesConfig,
      entriesConfig,
      setEntriesConfig,
    }),
    [entriesConfig]
  );

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};
