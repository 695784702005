import axios from 'axios';
import { ACCESS_TOKEN_KEY, USER_ID_KEY } from '@/src/constants/localStorageKeys';
const { VITE_ENDPOINT } = import.meta.env;

export const apiWithToken = axios.create({
  baseURL: VITE_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiWithToken.interceptors.request.use(
  async (config) => {
    const storageUserId = localStorage.getItem(USER_ID_KEY);
    const storageToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (storageUserId) {
      config.headers['User-Id'] = storageUserId;
    }
    if (storageToken) {
      config.headers.Authorization = `Bearer ${storageToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
