import Router from './routes';

import { AuthProvider } from '@/contexts/auth';
import { SWRConfig } from 'swr';

import { useMessageContext } from '@/contexts/message';
import { errorInterceptor, jwtExpiredMessage } from '@/src/utils/errorHelper';
import ErrorBoundary from '@/components/errorBoundary/ErrorBoundary';
import { StaticsProvider } from '@/contexts/statics';
import { useSearchParams } from 'react-router-dom';

function App() {
  const { showMessage } = useMessageContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const onError = (error: unknown, key: string) => {
    const err = errorInterceptor(error);
    showMessage({
      type: 'error',
      content: `Error ${err.status ?? ''}: ${err.message} ${key.split(',')[0]} `,
      key,
    });
    if (
      err.status === 401 &&
      err.message === jwtExpiredMessage &&
      !searchParams.get('refreshToken')
    ) {
      setSearchParams({ ...searchParams, refreshToken: 'true' });
    }
  };
  return (
    <ErrorBoundary>
      <SWRConfig
        value={{
          onError,
        }}
      >
        <AuthProvider>
          <StaticsProvider>
            <Router />
          </StaticsProvider>
        </AuthProvider>
      </SWRConfig>
    </ErrorBoundary>
  );
}

export default App;
