import styled from 'styled-components';
import { CenterContent } from '@/src/components';
import Button from '@/components/clickable/button/Button';
const { VITE_MAIN_HOST } = import.meta.env;

const NotAuthed = () => {
  const handleOnClick = () => {
    window.location.href = VITE_MAIN_HOST;
  };
  return (
    <Wrapper>
      <h1>401:</h1>
      <h2>Missing access rights</h2>
      <Button onClick={handleOnClick}>Go back to Maress</Button>
    </Wrapper>
  );
};

const Wrapper = styled(CenterContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 4rem);
  align-items: center;
  gap: ${({ theme }) => theme.space.md};
  > * {
    margin: 0;
    line-height: 1;
  }
`;

export default NotAuthed;
