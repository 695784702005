import React, { ElementType } from 'react';
import styled from 'styled-components';
import { ButtonSize, ButtonVariant } from '@/src/@types';
import { buttonReset } from '@/styles/variables';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: ButtonVariant;
  outline?: boolean;
  size?: ButtonSize;
  icon?: boolean;
  as?: ElementType;
  to?: string; // if used as a link
  className?: string;
};

export default function Button({
  children,
  onClick,
  variant = ButtonVariant.PRIMARY,
  outline = undefined,
  size = ButtonSize.DEFAULT,
  icon = undefined,
  as: Tag = 'button',
  to,
  className,
  ...rest
}: Props): JSX.Element {
  return (
    <Wrapper
      type="button"
      className={className}
      size={size}
      onClick={onClick}
      variant={variant}
      outline={outline?.toString()}
      icon={icon}
      as={Tag}
      to={to}
      {...rest}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.button<{
  variant: string;
  outline?: boolean;
  size?: ButtonSize;
  icon?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space.sm};
  ${buttonReset};
  box-sizing: border-box;
  padding-left: ${({ theme }) => theme.space.lg};
  padding-right: ${({ theme }) => theme.space.lg};
  min-width: 2.75rem;
  min-height: 2.75rem;
  border: 2px solid ${({ theme }) => theme.colors.brand};
  background-color: ${({ theme }) => theme.colors.brand};
  border-radius: 12px;
  color: ${({ theme }) => theme.colors.black500};
  transition: background-color 0.25s ease, color 0.25s ease, border-color 0.25s ease;
  &:hover:not([disabled]) {
    cursor: pointer;
  }
  &:hover:disabled {
    cursor: not-allowed;
  }
  svg {
    width: 1.5rem;
    path {
      stroke: currentColor;
    }
  }
  ${({ variant, theme }) =>
    variant === ButtonVariant.PRIMARY &&
    `
    &:hover:not([disabled]) {
      background-color: ${theme.colors.brandHover};
      border-color: ${theme.colors.brandHover};
    }
  `}
  ${({ disabled, variant, theme }) =>
    variant === ButtonVariant.PRIMARY &&
    disabled &&
    `
      background-color: ${theme.colors.brandDisabled};
      border-color: ${theme.colors.brandDisabled};
      opacity: 0.65;
  `}
  ${({ variant, outline, theme }) =>
    variant === ButtonVariant.PRIMARY &&
    outline &&
    `
      border-color: ${theme.colors.brand};
      background-color: transparent;
      color: ${theme.colors.brand};
       &:hover:not([disabled]) {
        color: ${theme.colors.brandHover};
        background-color: transparent;
        border-color: ${theme.colors.brandHover};
        text-decoration: underline;
      }
  `}
  ${({ variant, outline, disabled }) =>
    variant === ButtonVariant.PRIMARY &&
    outline &&
    disabled &&
    `
      opacity: 0.65;
  `}

  ${({ variant, theme }) =>
    variant === ButtonVariant.SECONDARY &&
    `
      border-color: ${theme.colors.white500};
      background-color: ${theme.colors.white500};
      color: ${theme.colors.black500};

      &:hover:not([disabled]) {
        background-color: ${theme.colors.grey500};
      }
  `}
  ${({ disabled, variant }) =>
    variant === ButtonVariant.SECONDARY &&
    disabled &&
    `
      opacity: 0.65;
  `}
  ${({ variant, outline, theme }) =>
    variant === ButtonVariant.SECONDARY &&
    outline &&
    `
      border-color: ${theme.colors.white500};
      background-color: transparent;
      color: ${theme.colors.grey500};

      &:hover:not([disabled]) {
        background-color: ${theme.colors.black900};
      }
  `}
  ${({ variant, outline, theme, disabled }) =>
    variant === ButtonVariant.SECONDARY &&
    outline &&
    disabled &&
    `
      border-color: ${theme.colors.white500};
      background-color: transparent;
      color: ${theme.colors.grey500};
  `}
  ${({ variant, theme }) =>
    variant === ButtonVariant.WARNING &&
    `
      border-color: ${theme.colors.warning};
      background-color: ${theme.colors.warning};

      &:hover:not([disabled]) {
        background-color: ${theme.colors.warningHover};
        border-color: ${theme.colors.warningHover};
      }
  `}
  ${({ variant, disabled, theme }) =>
    variant === ButtonVariant.WARNING &&
    disabled &&
    `
      border-color: ${theme.colors.warningDisabled};
      background-color: ${theme.colors.warningDisabled};
      color: ${theme.colors.black500};

  `}


  ${({ variant, outline, disabled }) =>
    variant === ButtonVariant.WARNING &&
    outline &&
    disabled &&
    `
      opacity: 0.65;
  `}
  ${({ variant, theme }) =>
    variant === ButtonVariant.PLAIN &&
    `
      border-color: transparent;
      background-color: transparent;
      color: ${theme.colors.textSecondary};
      &:hover:not([disabled]) {
        background-color: ${theme.colors.black900};
        border-color: ${theme.colors.black900};
        color: ${theme.colors.black500};
      }
      &:hover:disabled {
        cursor: not-allowed;
      }
  `}
  ${({ variant, theme }) =>
    variant === ButtonVariant.DARK &&
    `
      border-color: transparent;
      background-color: ${theme.colors.black800};
      color: ${theme.colors.textSecondary};
      &:hover:not([disabled]) {
        background-color: ${theme.colors.black900};
        border-color: ${theme.colors.black900};
      }
  `}
  ${({ variant, disabled, theme }) =>
    variant === ButtonVariant.DARK &&
    disabled &&
    `
      border-color: ${theme.colors.black500};
      background-color: ${theme.colors.black500};
      color: ${theme.colors.black900};

  `}
  ${({ variant, outline, theme }) =>
    variant === ButtonVariant.DARK &&
    outline &&
    `
      border-color: ${theme.colors.black600};
      background-color: transparent;
      color: ${theme.colors.black600};

      &:hover:not([disabled]) {
        background-color: ${theme.colors.textSecondary};
        border-color: ${theme.colors.black600};
        color: ${theme.colors.black500};
      }
  `}
  ${({ variant, outline, disabled, theme }) =>
    variant === ButtonVariant.DARK &&
    outline &&
    disabled &&
    `
      border-color: ${theme.colors.black900};
      opacity: 0.65;
  `}
  ${({ icon }) =>
    icon &&
    `
    padding: 0;
    width: 2.75rem;
  `}

  ${({ variant, theme }) =>
    variant === ButtonVariant.UNSTYLED &&
    `
    padding: 0;
    margin: 0;
    border-radius: 4px;
    background-color: unset;
    min-width: unset;
    min-height: unset;
    width: auto;
    height: auto;
    border: none;
    cursor: pointer;
    border: none;
    color: ${theme.colors.grey200};
    &:hover:not([disabled]) {
      color: ${theme.colors.black500};
      background-color: ${theme.colors.black900};
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.65;
    }
  `};
`;
