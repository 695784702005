export const mainTheme = {
  primary500: '#86e3d7',
  primary100: '#4Ac5b5',
  primary600: '#cdf3ee',
  primary900: '#e7fffc',

  secondary500: '#ee8762',
  secondary100: '#b6755f',
  secondary800: '#f4baa6',
  secondary900: '#ffEbe4',

  black500: '#1d2226',
  black600: '#25323a',
  black700: '#354651',
  black800: '#4b5d6c',
  black900: '#5d6f7e',

  grey500: '#d5dee1',
  grey300: '#bbc5cb',
  grey200: '#75838E',

  white500: '#f8f8f8',

  tertiary500: '#e9e07a',
  tertiary100: '#d2c377',

  danger500: '#DD5353',
  danger600: '#B73E3E',
};
