import { useMessageContext } from '@/contexts/message';
import { ACCESS_TOKEN_KEY, USER_ID_KEY } from '@/src/constants/localStorageKeys';
import { useTokenRenewal } from '@/src/hooks/useTokenRenewal';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Base64 } from 'js-base64';
import { useSearchParams } from 'react-router-dom';

export interface AuthContextData {
  isAuthed: boolean;
  setIsAuthed: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  userId?: string;
  setUserId: Dispatch<SetStateAction<string | undefined>>;
}
const initialValues: AuthContextData = {
  isAuthed: false,
  setIsAuthed: () => {
    console.log('setIsAuthed is not implemented');
  },
  isLoading: false,
  userId: undefined,
  setUserId: () => {
    console.log('setUserId is not implemented');
  },
};

export const AuthContext = createContext<AuthContextData>(initialValues);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { showMessage } = useMessageContext();
  const [userId, setUserId] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAuthed, setIsAuthed] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const authContext = useContext(AuthContext);
  const token = searchParams.get('token');

  const { isLoading: isRenewingToken } = useTokenRenewal({ showMessage, isAuthed, setIsAuthed });

  function setStorageData() {
    if (token == null) return;

    const decodedToken = Base64.atob(token);
    const keyValuePairs = decodedToken.split(';').map((pair) => pair.split('='));
    const parsedObject: { [key: string]: string } = {};

    keyValuePairs?.forEach(([key, value]) => {
      if (key && value) {
        parsedObject[key.trim()] = value.trim();
      } else {
        showMessage({ content: 'Invalid token format', type: 'error', key: 'auth' });
      }
    });

    if (Object.hasOwn(parsedObject, 'Auth-Token') && Object.hasOwn(parsedObject, 'User-Id')) {
      const authToken = parsedObject['Auth-Token'];
      const userId = parsedObject['User-Id'];
      authContext.setUserId(userId);
      authContext.setIsAuthed(true);
      localStorage.setItem(ACCESS_TOKEN_KEY, authToken);
      localStorage.setItem(USER_ID_KEY, userId);
      searchParams.delete('token');
      setSearchParams({ ...searchParams }, { replace: true });
      window.location.reload();
    } else {
      showMessage({
        content: 'Auth-Token and/or User-Id not found',
        type: 'error',
        key: 'auth',
      });
      authContext.setIsAuthed(false);
    }
  }

  function loadStorageData() {
    const storageUserId = localStorage.getItem(USER_ID_KEY);
    const storageAccessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (storageUserId && storageAccessToken) {
      setUserId(storageUserId);
      setIsAuthed(true);
    } else {
      setUserId(undefined);
      setIsAuthed(false);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (import.meta.env.MODE === 'development') {
      const user = import.meta.env.VITE_USER_ID_KEY;
      const token = import.meta.env.VITE_ACCESS_TOKEN_KEY;
      localStorage.setItem(USER_ID_KEY, user);
      localStorage.setItem(ACCESS_TOKEN_KEY, token);
    }

    /**
    Retrieve the token and userId from the base64-encoded token query parameter in the URL
    and store them in local storage.
    Load the stored variables to set the authentication status of the user.
    **/
    setStorageData();
    loadStorageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      isAuthed,
      setIsAuthed,
      isLoading: isLoading || isRenewingToken,
      userId,
      setUserId,
    }),
    [isAuthed, isLoading, isRenewingToken, userId]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContext.displayName = 'AuthContext';

export const useAuthContext = () => {
  return useContext(AuthContext);
};
