import { DefaultTheme } from 'styled-components';
import { mainTheme } from './colors';

export const theme: DefaultTheme = {
  fonts: {
    primary: 'Inter, "sans-serif"',
    primaryFallback: 'Inter, "sans-serif"',
    headingFont: 'Bai Jamjuree,  "sans-serif"',
    fontSizeBody: '1rem',
    fontSizeHtml: '16px',
  },
  space: {
    none: '0',
    xxs: '0.125rem',
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
    xxl: '4rem',
    xxxl: '8rem',
  },
  colors: {
    primary500: mainTheme.primary500,
    primary100: mainTheme.primary100,
    primary600: mainTheme.primary600,
    primary900: mainTheme.primary900,

    secondary500: mainTheme.secondary500,
    secondary100: mainTheme.secondary100,
    secondary800: mainTheme.secondary800,
    secondary900: mainTheme.secondary900,

    black500: mainTheme.black500,
    black600: mainTheme.black600,
    black700: mainTheme.black700,
    black800: mainTheme.black800,
    black900: mainTheme.black900,

    grey500: mainTheme.grey500,
    grey300: mainTheme.grey300,
    grey200: mainTheme.grey200,

    white500: mainTheme.white500,

    tertiary500: mainTheme.tertiary500,
    tertiary100: mainTheme.tertiary100,

    brand: mainTheme.primary500,
    brandHover: mainTheme.primary600,
    brandDisabled: mainTheme.primary100,
    brandPressed: mainTheme.primary900,

    success: mainTheme.primary500,

    link: mainTheme.primary500,
    linkHover: mainTheme.primary600,
    linkDisabled: mainTheme.primary100,

    text: mainTheme.white500,
    textSecondary: mainTheme.grey500,

    warning: mainTheme.secondary500,
    warningDisabled: mainTheme.secondary100,
    warningHover: mainTheme.secondary900,
    warningPressed: mainTheme.secondary900,

    danger: mainTheme.danger500,
    dangerHover: mainTheme.danger600,

    baseline: mainTheme.secondary500,
  },
  // for graphs, should be 20 entries
  colors10: [
    '#BBF7D0',
    '#E56F81',
    '#FEF9C3',
    '#AA62F1',
    '#56B5E2',
    '#5EEAD4',
    '#5F6DEC',
    '#C4B5FD',
    '#FDBA74',
    '#A5B4FC',
    '#D6D3D1',
  ],
  colors20: [
    '#BBF7D0',
    '#E56F81',
    '#FEF9C3',
    '#AA62F1',
    '#56B5E2',
    '#6EE7B7',
    '#C4B5FD',
    '#FDBA74',
    '#F3CFCF',
    '#A5F3FC',
    '#94A3B8',
    '#5F6DEC',
    '#93C5FD',
    '#8E80EA',
    '#FDE68A',
    '#A5B4FC',
    '#CE7B8F',
    '#D6D3D1',
    '#C4B5FD',
    '#D9F99D',
    '#A5B4FC',
    '#5EEAD4',
  ],
  specifiedColors: {
    // VesselActivities
    AH: '#FDBA74',
    AH_TOWING: '#b7a979',
    CONSTRUCTION: '#6EE7B7',
    CROSSING: '#D9F99D',
    DLP_UNDER_TOW: '#CE7B8F',
    DP: '#A5B4FC',
    DP_PLUS: '#C4B5FD',
    IDLE: '#AA62F1',
    MANEUVERING: '#A5F3FC',
    MIXED: '#D6D3D1',
    PORT: '#E56F81',
    SEISMIC_OPERATION: '#8E80EA',
    STANDBY: '#56B5E2',
    S_W_DEP_REC: '#5F6DEC',
    S_W_ECHO_S: '#93C5FD',
    S_W_ECHO_TOWING: '#A47BCE',
    TOWING: '#FDE68A',
    TRANSIT: '#BBF7D0',
    TRANSIT_ECO: '#5EEAD4',
    TRANSIT_MAX: '#FEF9C3',
    TRANSIT_OTHER: '#94A3B8',
    TUG_OPS: '#F3CFCF',
    // Weather
    WAVES: mainTheme.primary500,
    WIND: mainTheme.white500,
    // Other
    BASELINE: mainTheme.secondary500,
    GOAL: mainTheme.white500,
  },
};
