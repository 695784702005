import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { HashRouter } from 'react-router-dom';
import { G2 } from '@antv/g2plot';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import { g2Theme } from '@/styles/g2theme';
import 'leaflet/dist/leaflet.css';

import GlobalStyles from './styles/globalStyles';
import App from './App';
import { MessageProvider } from '@/contexts/message';
import MainLayout from '@/components/MainLayout';
import { MarkerIconProvider } from '@/contexts/marker-icon';
import { AppConfigProvider } from '@/contexts/config';
import MarkerSettingsProvider from '@/contexts/vessel-marker-settings';

const { registerTheme } = G2;
registerTheme('vps-theme', g2Theme);

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <ThemeProvider theme={theme}>
        <MessageProvider>
          <MarkerIconProvider>
            <AppConfigProvider>
              <MarkerSettingsProvider>
                <HashRouter>
                  <GlobalStyles />
                  <MainLayout>
                    <App />
                  </MainLayout>
                </HashRouter>
              </MarkerSettingsProvider>
            </AppConfigProvider>
          </MarkerIconProvider>
        </MessageProvider>
      </ThemeProvider>
    </StrictMode>
  );
}
